@import "../../../../App.scss";

.ai_insight_wrapper {
  padding: 12px;
  border-radius: 12px;

  .ai_insight_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;

    .ai_title_wrapper {
      display: flex;
      align-items: center;
      gap: 6px;

      .ai_flower_logo {
        // margin-right: 6px;
      }

      .ai_title {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        @include font_gt_walsheim_medium;
        margin-right: 4px;
      }

      .info_icon {
        margin-left: -7px;
      }
    }

    .update_date_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
    }

    .update_date {
      font-size: 14px;
      line-height: 16px;
      @include font_gt_walsheim_medium;
    }

    .dropdown_icon {
      transition: transform 0.3s ease;
      height: 18px;
      width: 18px;
      margin-left: 15px;
      cursor: pointer;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .ai_content_wrapper {
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    max-height: 50vh;
    overflow-y: auto;
  }

  .ai_content {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    @include font_gt_walsheim;
  }

  .thumbs_wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;

    .thumbs_icon {
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
