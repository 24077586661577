@import "../../../App.scss";

.abo_list_container {
  .abo_list_title {
    font-size: 16px;
    line-height: 24px;
    @include font_gt_walsheim_medium;
    padding: 8px 0;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .abo_textline_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .ai_logo {
      margin-right: 16px;
    }

    .textline {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim;
    }
  }

  .profile_list {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    .list_title {
      @include font_gt_walsheim_medium;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
  .followup_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 40px;
    padding-top: 32px;
  }
  .followup_heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    text-transform: uppercase;
    padding : 24px 0 0; 
  }  

  .followup_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 32px;
    cursor: pointer;
    @include font_gt_walsheim_medium;
    line-height: 24px;

    .followup_arrow {
      border-radius: 25px; 
      display: flex;      
      justify-content: center;  
      align-items: center;     
      flex-shrink: 0;
    }
  }
  .followup_text {
    font-size: 16px;
    line-height: 24px;
    .question_text {
      margin-bottom: 32px;
    }
  }
  .no_data {
    font-size: 16px;
    line-height: 24px;
    padding-top: 24px;
    margin-bottom: 48px;
  }
}