@import "../../../../App.scss";

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  .input_wrapper {
    display: flex;
    position: relative;

    .dropdown_input {
      border-radius: 12px;
      width: 100%;
      height: 56px;
      padding: 16px;
      padding-right: 36px;
      font-size: 16px;
      @include font_gt_walsheim_medium;
      line-height: 24px;
      pointer-events: none;
      box-sizing: border-box;

      &.input_with_text {
        padding: 20px 16px 7px;
        box-sizing: border-box;
        height: 56px;
      }
    }

    .placeholder {
      position: absolute;
      font-size: 14px;
      line-height: 18px;
      @include font_gt_walsheim_medium;
      transition: all 0.2s ease;
      padding: 7px 17px;
    }

    .arrow_icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease-in-out;
      cursor: pointer;

      &.rotate {
        transform: rotate(180deg);
        top: 35%;
      }
    }
  }

  margin-bottom: 24px;

  .dropdown_menu {
    position: relative;
    top: 8px;
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    transition: max-height 0.5s ease-in-out;

    &.dropdown_pos {
      position: absolute;
      top: 64px;
    }

    &.dropdown_loader {
      padding: 10px;
    }

    .dropdown_item {
      min-height: 44px;
      padding: 12px;
      align-items: center;
      cursor: pointer;
      @include font_gt_walsheim_medium;

      &.loader {
        margin: 0 8px;
        margin-bottom: 7px;
        height: 37px;
        border-radius: 8px;
      }
    }

    .dropdown_item.selected {
      @include font_gt_walsheim_bold;
    }
  }

  .dropdown.open .dropdown_menu {
    max-height: 308px;
  }

  .search_bar_container {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 18px;
    padding: 12px;
    height: 36px;
    margin: 12px 8px 12px 8px;

    .search_icon {
      cursor: pointer;
    }

    .search_input {
      flex: 1;
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 16px;
      line-height: 24px;
      padding: 0 12px;
      @include ellipsis;
      cursor: text;
      @include font_gt_walsheim_medium;
    }
    .clear_search {
      cursor: pointer;
    }
  }
  .dropdown_list{
    height: 227px;
    overflow-y: auto;
  }
}

.no_result_text {
  font-size: 14px;
  line-height: 18px;
  @include font_gt_walsheim_medium;
  padding: 12px;
}