@import "../../App.scss";

.hatv_main_container {

  .hatv_outer_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 32px;
    @include breakpoint(md) {
      padding-top: 48px;
    }
    .landing_page {
      width: 100%;
      padding: 0 32px;
      @include breakpoint(md) {
        width: 800px;
        padding: 0;
      }
    }
  }

}