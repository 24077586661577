@import "../../../App.scss";

.hatv_inner_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow:hidden;

  .hatv_approval_experiance {
    width: 100%;
    @include breakpoint(md) {
      width: 100%;
    }
    .hatv_approval_header {
      display: flex;
      flex-direction: row;

      .iconOuter {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        align-items: center;
        cursor: pointer;
        margin-right: 16px;
      }

      .ai_flower_logo {
        border-radius: 50%;
      }

      .approval_header_outer {
        display: flex;
        justify-content: center;
        align-items: left;
        flex-direction: column;
        gap: 4;

        .approval_header {
          @include font_gt_walsheim_bold;
          font-size: 24px;
          line-height: 28px;
          @include breakpoint(md) {
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
    }

    .aboListApprovalContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      padding: 24px 16px;
      height: 80px;
      border: 1px solid #77CBBF;
      border-radius: 12px;
      min-width: 100%;
      
      @include breakpoint(md) {
        width: 800px;
      }
      .aboListApprovalText {
        @include font_gt_walsheim_medium;
        font-size: 16px;
        line-height: 24px;
        @include breakpoint(md) {
          font-size: 18px;
          line-height: 21px;
        }
      }

      .rightArrowIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}